<template>
  <div style="padding: 16px;">
    <!-- 水用了多少，粉是多少。粉水比多少  有没有加冰。冰多重 -->
    <div style="font-size: 36px;">
      手冲咖啡
      <div style="font-size: 20px; margin-top: 14px;">粉水计算器</div>
    </div>
    <div style="display: flex; flex-direction: column; margin-top: 20px; background-color: #18223A; padding: 16px; border-radius: 8px;">
      <div style="" class="flex">
        初始水量
      </div>
      <div style="display: flex; margin-top: 16px;">
        <div style="flex: 1; display: flex; justify-content: center;">

          

          <input type="text" style="background-color: #161616; border: none; padding: 8px; width: 50px; border-radius: 4px; ">
          粉量 （克）
          <!-- <van-cell-group>
            <van-field v-model="value" placeholder="请输入用户名" />
          </van-cell-group> -->
        </div>
        <div style="flex: 1; display: flex; justify-content: center;">
          水量
        </div>
      </div>
        

      <!-- 默认粉水比为 1:16 ，点击修改

      是否加冰  switch

      出现一个冰的输入框 -->
    </div>

    <div>是否加冰</div>

    <div>冰的重量  一个输入框</div>

    <div>最后需要的水量</div>

    <div>冲泡步骤</div>

    <div>多少克水闷蒸30秒</div>

    <div>第二段多少水</div>

    <div>间隔多少秒</div>
    <div>第三段多少水</div>
    <div>什么情况下移走滤杯</div>
    <div>然后再加多少水</div>
    <!-- 是否拼配，拼配比例 -->
  </div>

  
</template>

<script>
export default {
  data() {
    return { }
  },
  mounted() { },
  components: { },
  methods: { }
}
</script>

<style>

</style>